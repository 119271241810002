import React from 'react';
import {
    Box,
    VStack,
    Text,
    Heading,
} from '@chakra-ui/react';

// markup
const IndexPage = () => {
    return (
        <VStack h="100vh" w="100vw" bg="gray.700" justifyContent="center">
            <Box color="white">
                <Heading>brightstar.lv</Heading>
                <Text fontSize="xl">Site is under development</Text>
            </Box>
        </VStack>
    );
};

export default IndexPage;
